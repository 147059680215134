<template>
    <div id="termsandcondition">
        <h1 class="text-center">
            Terms of Service
        </h1>
        <p>
            By using this website to search for rental listings, you acknowledge and agree that:
        </p>
        <ol>
            <li>
                <p>
                    <b>Information Accuracy</b>: While we strive to ensure the accuracy of the information presented, we do not guarantee that all rental listings, pricing, availability, or other details are current or free of errors. You are encouraged to independently verify all information before making any rental decisions.
                </p>
            </li>
            <li>
                <p>
                    <b>Third-Party Content</b>: This website may feature rental listings and information provided by third parties. We are not responsible for the accuracy, legality, or completeness of such content and disclaim any liability arising from your reliance on third-party information.
                </p>
            </li>
            <li>
                <p>
                    <b>Use of Third-Party Services</b>: Our website may rely on third-party service providers for certain functionalities, features, or data, including but not limited to payment processing, data aggregation, or map services. While we strive to partner with reputable providers, we do not control or guarantee the actions, policies, or performance of these third-party services. Cerda-Zein is not responsible for a third party's services that conflict with this Terms of Agreement or Cerda Zein’s Privacy Policy. Cerda-Zein disclaims any responsibility or liability related to these third parties.
                </p>
            </li>
            <li>
                <p>
                    <b>No Guarantees</b>: Your use of the website does not create any guarantee of securing a rental property or a particular outcome in your search process.
                </p>
            </li>
            <li>
                <p>
                    <b>Prohibited Use</b>: You agree not to use the website for any unlawful, fraudulent, or malicious activities, including but not limited to misleading other users, submitting false information, or scraping data from the platform.
                </p>
            </li>
            <li>
                <p>
                    <b>Modifications and Availability</b>:We reserve the right to modify, update, or discontinue any features or functionalities of this website at any time without prior notice. We are not liable for any disruptions, errors, or loss of access resulting from such changes.
                </p>
            </li>
            <li>
                <p>
                    <b>Limitation of Liability</b>: To the fullest extent permitted by law, Cerda-Zein and its affiliates, officers, employees, and agents shall not be liable for any damages, including direct, indirect, incidental, or consequential damages, arising from or related to your use of the website.
                </p>
            </li>
            <li>
                <p>
                    <b>Indemnification</b>: You agree to indemnify and hold harmless Cerda-Zein Real Estate, its affiliates, officers, employees, and agents from any claims, liabilities, damages, or expenses resulting from your violation of these Terms of Service or misuse of the website.
                </p>
            </li>
            <li>
                <p>
                    <b>Updates to Terms</b>: We may update these Terms of Service from time to time. You are advised to review these Terms of Service periodically for any changes. Continued use of the website after such updates constitutes your acceptance of the revised terms.
                </p>
            </li>
        </ol>
        <p>
            By accessing and using the website, you signify your understanding and agreement to abide by these terms and any additional provisions that may apply.
            <br><br>
            Updated 2025.
        </p>
    </div>
</template>

<script>
export default {
    name: "TermsAndCondition",
    metaInfo: {
        title: 'Terms and Condition',
        titleTemplate: '%s | Cerda-Zein',
        meta: [{
            name: 'description', 
            content: "Cerda-Zein"
        }]
    },
}
</script>

<style lang="scss" scoped>
#termsandcondition{
    text-align: left;
    margin: 20px 40px;
}

.text-center{
    text-align: center;
}
</style>
